<template>
  <div>
    <eden-page-header
      :title="'Beauticians Directory'"
      :subtitle="'Add new beautician'"
    />
    <beautician-form :action="'add'" />
  </div>
</template>

<script>
import BeauticianForm from "@/components/Logistics/Directory/Beauticians/BeauticianForm.vue";

export default {
  name: "BeauticianAdd",
  components: { BeauticianForm },
};
</script>
